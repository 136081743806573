import React, { useState } from "react";
import { IMAGES } from "../../../components/images";
import { Link, useNavigate } from "react-router-dom";
import { handleRequest } from "../../../services";
import { SIGNIN } from "../../../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROUTES } from "../../../routes/routes";
import Spinner from "../../../components/loader/loader"

const TryFree = () => {
  const [name, setName] = useState("Asocialite");
  const [gender, setGender] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  // const [isTermsAgreed, setIsTermsAgreed] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleGenderClick = (genderCode) => {
    setGender(genderCode);
  };

  const handleTermsChange = () => {
    setTermsAgreed(!termsAgreed);
    // setIsTermsAgreed("");
  };

  const handleValidation = () => {
    let validate = true;
    if (!termsAgreed) {
      validate = false;
    }
    return validate;
  };

  const generateUniqueId = () => {
    return `user_${Date.now()}`;
  };

  const handleSubmit = async (e) => {

    setLoading(true);
    e.preventDefault();
    let userId = localStorage.getItem('uniqueUserId');
    if (!userId) {
      userId = generateUniqueId();
      localStorage.setItem('uniqueUserId', userId);
    }

    // if (handleValidation()) {
      const data = {
        account_type: "0",
        device_token: userId,
        name: "Asocialite"
      };

      if (gender) {
        data["gender"] = gender;
      }

      try {
        const response = await handleRequest("POST", SIGNIN, data);
        console.log(response);
        if (response.code === 200) {
          if (response.body.token) {
            // save token in local storage
            localStorage.setItem("userData", JSON.stringify(response.body));
            localStorage.setItem("token", JSON.stringify(response?.body?.token))
            localStorage.setItem("tryUserAccountType", (response?.body?.userdata.account_type))
            localStorage.setItem("otherFriendAccountType", 0)
            navigate(ROUTES.HOME);
          }
        }
        else if (response.code === 400) {
          toast.info(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    // }
    setLoading(false);
  };

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
            <div className="bg-white w-100 box-shadow login-radius">

      <div className="heading">
        <h3 className="mb-1">Try it Out</h3>
        <p className="fw-400 mb-2">Enter your details</p>
      </div>
      <div className="upper-space">
        <form onSubmit={handleSubmit}>
          <div className="">
            <label className="padding-bottom-4">Name</label>
            <div className="form-group position-relative">
              <input
                onChange={() => setName(name)}
                style={{ color: "gray" }}
                type="text"
                value={name}
                placeholder="Screen Name/nickname"
                className="input-field w-100 left-padding"
                disabled
              />
              <div className="phone-icon">
                <img src={IMAGES.USERNAME} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="padding-top-4">
            <label className="pb-4">Gender</label>
            <div className="row">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleGenderClick(1)}
                className="col-6 col-md-4 mb-3"
              >
                <div className="gender-box ">
                  <div className={`gender-inner ${gender === 1 && "active"}`}>
                    <img
                      src={
                        gender === 1
                          ? IMAGES.MALE_SELECTED
                          : IMAGES.MALE_UNSELECTED
                      } className="gender-inner-first-img"
                    />
                  </div>
                  <p className="text-center pt-1 mb-0">Male</p>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleGenderClick(2)}
                className="col-6 col-md-4 mb-3"
              >
                <div className="gender-box ">
                  <div className={`gender-inner ${gender === 2 && "active"}`}>
                    <img
                      src={
                        gender === 2
                          ? IMAGES.FEMALE_SELECTED
                          : IMAGES.FEMALE_UNSELECTED
                      } className="gender-inner-img"
                    />
                  </div>
                  <p className="text-center pt-1 mb-0">Female</p>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleGenderClick(3)}
                className="col-6 col-md-4 mb-3"
              >
                <div className="gender-box ">
                  <div className={`gender-inner ${gender === 3 && "active"}`}>
                    <img
                      src={
                        gender === 3
                          ? IMAGES.NOBINARY_SELECTED
                          : IMAGES.NOBINARY_UNSELECTED
                      } className="gender-inner-img"
                    />
                  </div>
                  <p className="text-center pt-1 mb-0">Nonbinary</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={termsAgreed}
              onChange={handleTermsChange}
            />
            <label className="fw-400">
              Agree to our <span className="sec-theme fw-500" onClick={() => openLink('https://asocial.chat/terms-of-service/')}
                style={{ cursor: 'pointer' }}> Terms </span>
              and <span className="sec-theme fw-500" onClick={() => openLink("https://asocial.chat/privacy-policy/")}
                style={{ cursor: 'pointer' }}> Data policy </span> .
            </label>
          </div> */}
          {/* {isTermsAgreed &&
           <p className="text-danger">{isTermsAgreed}</p>} */}
          <div className="top-space">
            {loading ?
              <div className="loader-style">
                <Spinner />
              </div>
              : <button type="submit" className="gradient-btn border-0 w-100">
                Submit
              </button>}
          </div>
        </form>
      </div>
      <ToastContainer
        theme="colored"
      />
      </div>
    </>
  );
};

export default TryFree;
