import React, { useEffect, useState, useRef, useCallback } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { handleRequest } from "../../../services";
import {
  CREATE_LOG,
  GENERATE_RTC_TOKEN,
  GET_ALL_LIVE_USERS,
  ONLINE_USER,
  OTHER_USER_DETAIL,
  UPDATE_SWIPELIMIT,
  BLOCK_USER,
  IMAGEURL,
  FRIEND_UNFRIEND,
  GET_MATCHED_INTEREST_LIST
} from "../../../config";
import { IMAGES } from "../../../components/images";
import Webcam from "react-webcam";
import VideoPlayer from "./VideoPlayer";
import { useSidebarContext } from "../../../context/sidebarContext";
import io from "socket.io-client";
const APP_ID = import.meta.env.VITE_AGORA_APP_ID
import { SOCKETURL } from "../../../config";
import Lottie from "lottie-react";
import loader_gif from "../../../assets/gif/loader_gif.json"
import { toast } from 'react-toastify';
import MatchedInterestModal from "../../modals/MatchedInterestModal";
import FriendRequestCustomToast from './FriendRequestCustomToast';
import Spinner from "../../../components/loader/loader";
import ConfirmModal from "../../modals/confirmModal";

const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});
const socket = io(SOCKETURL);

const VideoRoom = (props) => {
  const { user_id, setEndedChatModal, isAutoRollOn, setOtherUser, setShowChat, handleShowChatWindow, accountType, otherUser, setVideoCallStart, setIsInterestModalOpen, isInterestModalOpen, webcamLoading, setWebcamLoading, interestArray, setInterestArray, videoCallStart, toastId, endedChatModal, setConfirmation, confrimation } = props;
  const { setChannelForChat, setMessages,
    setCallStartTime,
    setCallEndTime, setCallStartOpen,
    userDetail, videoButtonOpen, setIsSidebarVisible
  } = useSidebarContext();
  const [imagesLoading, setImagesLoading] = useState();
  const [room, setRoom] = useState();
  const [webcamInitialized, setWebcamInitialized] = useState(false);
  const [themVideo, setThemVideo] = useState();
  const [myVideo, setMyVideo] = useState();
  const [themAudio, setThemAudio] = useState();
  const autoRollOnRef = useRef(isAutoRollOn);
  const channelRef = useRef();
  const rtcClientRef = useRef();
  const userJoined = useRef(false)
  const [localTracks, setLocalTracks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [muteCall, setMuteCall] = useState(false);
  const [leave, setLeave] = useState(false);
  const [friendRequest, setFriendRequest] = useState(otherUser?.friendStatus);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [friendRequestAccountType, setFriendRequestAccountType] = useState(true);
  const tryUserAccountType = localStorage.getItem("tryUserAccountType")
  const interest_matching = localStorage.getItem("interest_matching")
  const [startCountdown, setStartCountdown] = useState(false);
  const [response, setResponse] = useState(false);

  const videoConstraints = {
    width: 1280,
    // height: 720,
    facingMode: "user"
  };

  const [friend, setFriend] = useState(false)
  const [toastShown, setToastShown] = useState(false);
  const showToast = (res) => {
    toast(<FriendRequestCustomToast sender_id={res?.id} sender_name={res?.name} setFriendRequest={setFriendRequest} />, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 10000,
      onClose: () => setToastShown(false)
    });
  }

  useEffect(() => {
    socket.on(`getRequest${user_id}`, (response) => {
      if (response && !toastShown) {
        setToastShown(true);
        showToast(response);
        console.log(response, "response")
      }
    });
    return () => {
      socket.off(`getRequest${user_id}`);
    }
  }, [user_id, toastShown]);

  useEffect(() => {
    socket.on(`getStatus${user_id}`, (response) => {
      if (response) {
        if (response.status == 1) {
          setFriendRequest(null)
        }
        else {
          setFriendRequest(response.status)
        }
      }
    });
    return () => {
      socket.off(`getStatus${user_id}`);
    }
  }, [friend])



  const getOtherUserDetail = async (id, check) => {
    try {
      const resposne = await handleRequest("GET", `${OTHER_USER_DETAIL}?user_id=${id}`)
      localStorage.setItem("otherUserId", resposne.body.id)
      setOtherUser(resposne?.body);
      setFriendRequestAccountType(true)
      setFriendRequest(resposne.body.friendStatus)

    } catch (error) {
      console.log(error)

    }
  }

  const makeUserOnlineorOffline = async (type) => {
    const data = {
      type: type,
      socketId: socket.id
    };
    try {
      const response = await handleRequest("POST", ONLINE_USER, data);
      console.log(response, "response")
    } catch (error) {
      console.log(error);
    }
  };

  const getInterestList = async () => {
    try {
      const response = await handleRequest("GET", GET_MATCHED_INTEREST_LIST + `?otherUserID=${localStorage.getItem("otherUserId")}`);
      setInterestArray(response.body)
      console.log(response.body, "bodyyyy")
      setTimeout(() => {
        setIsInterestModalOpen(true);
      }, 500)

    } catch (error) {
      console.log(error)
    }
  }

  const getLiveUsersList = async () => {
    try {
      const socketPromise = new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error("Socket response timed out"));
        }, 5000);

        socket.on(`getallLiveUsers${user_id}`, (socketResponse) => {
          clearTimeout(timeout);
          console.log(socketResponse, "socketResponse");
          resolve(socketResponse);
        });
      });

      const response = await handleRequest("GET", GET_ALL_LIVE_USERS);
      console.log(response.body, "userrrrrrrrrrrrrrrrrrrrrr");

      if (response.code == 404) {
        console.log("Retrying getLiveUsersList in 10 seconds...");
        await new Promise(res => setTimeout(res, 10000));
        return getLiveUsersList();
      }

      const result = await socketPromise;
      return result;

    } catch (error) {
      console.error("Error in getLiveUsersList:", error);
      return [];
    }
  };


  useEffect(() => {
    const connectRoutine = async () => {
      const videoStart = localStorage.getItem("videoStart")
      if (startCountdown && videoStart == 0 && !response) {
        await connectToUser();
        console.log("Attempting to connect to users...");
      }
    };

    const intervalId = setInterval(() => {
      connectRoutine();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [startCountdown]);


  const createChannel = async (id) => {
    try {
      const response = await handleRequest(
        "GET",
        `${GENERATE_RTC_TOKEN}?user_id=${id}`
      );
      setRoom(response.body.channel);
      setChannelForChat(response.body.channel);
      return response;
    } catch (error) {
      console.log(error)
    }
  };

  const connectToAgoraRtc = async (
    channel,
    token,
    onVideoConnect,
    onWebcamStart,
    onAudioConnect,
    userId

  ) => {

    if (client.connectionState == "CONNECTING" || client.connectionState == "CONNECTED") {
      console.log("Client is already in CONNECTING or CONNECTED state");
      return;
    }

    try {
      await client.join(APP_ID, channel, token, null);
      console.log("Joined channel:", channel);
      console.log("Current remote users after joining:", client.remoteUsers);

      socket.off(`getallLiveUsers${userId}`);
      client.on("user-published", async (themUser, mediaType) => {
        console.log(themUser, "themmmm")
        try {
          await client.subscribe(themUser, mediaType);
          console.log("Subscribed to user:", themUser.uid, "MediaType:", mediaType);
          if (mediaType === "video") {
            const videoTrack = themUser.videoTrack;
            onVideoConnect(videoTrack);
            console.log("Video track connected:", videoTrack);
          }

          if (mediaType === "audio") {
            const audioTrack = themUser.audioTrack;
            setTimeout(async () => {
              await playConnectionSound();
              onAudioConnect(audioTrack);
              audioTrack?.play();
              console.log("Audio track connected and playing:", audioTrack);
            }, 1500);
          }

          await makeUserOnlineorOffline("2");
          localStorage.setItem("otherFriendAccountType", 1);

        } catch (error) {
          console.error("Error subscribing to user:", error);
        }
      })

      client.on("user-joined", async (user) => {
        console.log("User joined:", user.uid);
        console.log(client.remoteUsers, "remoteeee", user)
        if (!userJoined.current) {
          try {
            await makeUserOnlineorOffline("2");
            await handleUpdateSwipeLimit();
            setCallStartTime(new Date());
            userJoined.current = true;
          } catch (error) {
            console.error("Error handling user join:", error);
          }
        }
      });

      // Create local audio and video tracks
      const [audioTrack, videoTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack()
      ]);

      setLocalTracks([audioTrack, videoTrack]);
      onWebcamStart(videoTrack);

      // Publish local tracks
      await client.publish([audioTrack, videoTrack]);
      console.log("Published local tracks:", audioTrack, videoTrack);

      // Clean up socket listeners after publishing
      socket.off(`getallLiveUsers${userId}`);

      console.log(client.remoteUsers, client.remoteUsers.length, "uuuuuuuuuuuuuuuuuu")

      setTimeout(async () => {
        console.log("Final remote users check:", client.remoteUsers, "Length:", client.remoteUsers.length);
        if (client.remoteUsers?.length === 0) {
          console.log("No remote users present");
          await handleLeaveCall(); 
        }
      }, 2000);
      
      // Additional UI state changes
      setTimeout(() => {
        setCallStartOpen(true);
        setVideoCallStart(true);
        setLoading(false);
        localStorage.setItem("videoStart", 1);
      }, 1500);

      // Interest matching if applicable
      if (interest_matching === 1) {
        setTimeout(() => {
          getInterestList();
        }, 1000);
      }

      return { tracks: [audioTrack, videoTrack], client };

    } catch (error) {
      setLoading(false);
      console.error("Error connecting to Agora RTC:", error);
      alert('Camera or microphone not allowed');
    }
  };


  const playConnectionSound = () => {
    const audio = new Audio(IMAGES.AUDIO);
    audio.play();
  }

  const connectToUser = async () => {
    setThemAudio(undefined);
    setThemVideo(undefined);
    setMyVideo(undefined);
    if (!isLoading) {
      setLoading(true);
    }
    if (rtcClientRef.current) {
      await rtcClientRef.current.leave();
    }
    await makeUserOnlineorOffline("1");
    const getLiveUsersListResponse = await getLiveUsersList();
    console.log(getLiveUsersListResponse, "getLiveUsersListResponse")
    const onlineUsers = getLiveUsersListResponse.filter(user => user.is_online == 1);

    if (onlineUsers?.length > 0) {
      const userToConnect = onlineUsers[0];

      if (userToConnect.id) {
        await createLogs(userToConnect.id);
        await getOtherUserDetail(userToConnect.id);
      }
      const createChannelResponse = await createChannel(userToConnect.id);
      const { tracks, client } = await connectToAgoraRtc(
        createChannelResponse.body.channel,
        createChannelResponse.body.token,
        (themVideo) => setThemVideo(themVideo),
        (myVideo) => setMyVideo(myVideo),
        (themAudio) => setThemAudio(themAudio),
        userToConnect.id
      );
      rtcClientRef.current = client;
    } else {
      await makeUserOnlineorOffline("1");
    }
  };

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      console.log("img load")
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = () => {
        console.warn(`Failed to load image: ${url}`);
        reject(new Error(`Image failed to load: ${url}`));
      };
    });
  };

  const handleStartCall = async () => {
    setImagesLoading(true)
    try {
      if (userDetail?.account_type === 0 && userDetail?.swipe_limit === 0) {
        alert("Swipe limit reached!! Kindly Register");
        return;
      }
      setEndedChatModal(false);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      stream.getTracks().forEach(track => track.stop());
      if (userDetail.account_type != 0) {
        await preloadImage(IMAGEURL + userDetail.avatar)
        setImagesLoading(false)
        setLoading(true);
        localStorage.setItem("videoStart", 0)
        connectToUser();
        setStartCountdown(true);
      }
      else {
        setImagesLoading(false)
        setLoading(true);
        localStorage.setItem("videoStart", 0)
        connectToUser();
        setStartCountdown(true);
      }


    } catch (error) {
      console.error("Error in handleStartCall:", error);
      setLoading(false);
      if (error.name === 'NotAllowedError') {
        alert('Camera and microphone access both are required to start call');
      }
      else {
        alert('Camera or microphone access denied. Please allow access to start the call.');
      }
    }
  };

  const handleSendButtonClick = () => {
    if (toastId) {
      toast.dismiss(toastId);
    }
  };

  const handleUpdateSwipeLimit = async () => {
    try {
      const response = await handleRequest("GET", UPDATE_SWIPELIMIT);
    } catch (error) {
      console.log(error)
    }
  }

  const createLogs = async (id) => {
    try {
      await handleRequest("GET", CREATE_LOG + id);
    } catch (error) {
      console.log(error);
    }
  };


  const handleNextClick = async () => {
    await handleLeaveCall("end");
    setCallStartOpen(false);
    setVideoCallStart(false);
    handleStartCall();
  };

  const isChatting = room;
  const handleLeaveCall = async (end) => {
    socket.off(`getallLiveUsers${user_id}`);
    if (!webcamInitialized && end != "end" && localStorage.getItem("auto") != 0) {
      await initializeWebcam();
      setWebcamLoading(true);
    }
    localStorage.setItem("auto", 1)
    if (videoCallStart) {
      // handleBlockUserCall()
    }
    setIsInterestModalOpen(false)
    userJoined.ref = false
    setMuteCall(false);
    await setCallEndTime(new Date());
    if (localStorage.getItem("token")) {
      await makeUserOnlineorOffline("2");
    }
    await myVideo?.close();
    await themVideo?.close();
    await themAudio?.close();
    if (end != "end") {
      setCallStartOpen(false);
    }
    if (channelRef.current) {
      await channelRef.current.leave();
    }
    // if (localTracks.length > 0) {
    //   localTracks.forEach((track) => {
    //     track.stop();
    //     track.close();
    //   });
    //   setLocalTracks([]);
    // }
    if (rtcClientRef.current) {
      rtcClientRef.current.leave();
    }
    setRoom('');
    setMessages([]);
    setShowChat(false);
    setLoading(false);
    setVideoCallStart(false);
    setThemVideo(null);
    setMyVideo(null);
    localStorage.setItem("otherFriendAccountType", 0);
    setConfirmation(false)
    localStorage.setItem("videoStart", 0)
    setResponse(false)
    setStartCountdown(false)
  };

  const initializeWebcam = async () => {
    try {
      // await navigator.mediaDevices.getUserMedia({ video: false });
      setWebcamLoading(false);
      setWebcamInitialized(true);
    } catch (error) {
      console.error("Error initializing webcam:", error);
      setWebcamLoading(false);
    }
  };

  const handleBlockUser = async () => {
    // const data = {
    //     blocked_user_id: otherUser.id
    // }
    try {
      const response = await handleRequest("GET", `${BLOCK_USER}?block_user_id=${otherUser.id}`);
      //  response.body;
      toast.success(response.message)
      handleLeaveCall()
    } catch (error) {
      console.log(error)
    }
  }
  const handleBlockUserCall = async () => {
    try {
      const response = await handleRequest("GET", `${BLOCK_USER}?block_user_id=${localStorage.getItem("otherUserId")}&block_type=1`);
      // toast.success(response.message)
    } catch (error) {
      console.log(error)
    }
  }

  const handleMuteUnmute = () => {
    localTracks[0].setMuted(!muteCall)
    setMuteCall(!muteCall);
  }

  const handleAddFriend = async () => {
    try {
      const response = await handleRequest("GET", FRIEND_UNFRIEND + otherUser.id);
      toast.info(response.message);
      setFriendRequest(0)
      setFriend(true)
    } catch (error) {
      console.log(error)
    }
  }


  const handleFriendRequest = () => {
    if (friendRequest === null) {
      handleAddFriend()
      getOtherUserDetail(otherUser?.id);
    }
  }

  // useEffect(() => {
  //   if (isLoading) {
  //     const timeoutId = setTimeout(async () => {
  //       // await handleLeaveCall("end")
  //       alert("No user found. Please try again.");
  //     }, 30000);
  //     return () => {
  //       clearTimeout(timeoutId);
  //     };
  //   }
  // }, [isLoading]);

  useEffect(() => {
    autoRollOnRef.current = isAutoRollOn;
  }, [isAutoRollOn]);

  useEffect(() => {
    const handleReturnLiveChannelList = async (response) => {
      console.log(response, "responseresponseresponse")
      setResponse(true)
      await makeUserOnlineorOffline("1");
      setRoom(response.channel);
      setChannelForChat(response.channel);
      if (response.user_id) {
        await createLogs(response?.user_id);
        await getOtherUserDetail(response?.user_id);
      }
      const result = await connectToAgoraRtc(
        response.channel,
        response.token,
        (themVideo) => setThemVideo(themVideo),
        (myVideo) => setMyVideo(myVideo),
        (themAudio) => setThemAudio(themAudio),
        response.user_id
      );
      console.log(result, "Agora connection result");

      if (result) {
        const { tracks, client } = result;
        rtcClientRef.current = client;
      } else {
        console.error("connectToAgoraRtc did not return a valid result");
        // setLoading(true)
        // handleLeaveCall();

      }
    };

    socket.on(`returnLiveChannelList${user_id}`, handleReturnLiveChannelList);

    client.on("user-left", async (user, reason) => {
      userJoined.current = false;
      setMuteCall(false);
      // handleBlockUserCall()
      await setCallEndTime(new Date());
      await handleLeaveCall();
      await setEndedChatModal(true);
      setTimeout(() => {
        if (autoRollOnRef.current && !endedChatModal) {
          handleStartCall();
        }
      }, 3000);
    });

    client.on("crypt-error", async () => {
      await handleLeaveCall();
    });

    client.on("live-streaming-error", async () => {
      await handleLeaveCall();
    });

    window.addEventListener("beforeunload", async () => {
      await handleLeaveCall();

    });

    return async () => {
      socket.off(`returnLiveChannelList${user_id}`, handleReturnLiveChannelList);
      client.off("user-left");
      if (leave) {
        console.log("99999")
        handleLeaveCall("end");
        setLeave(false)
      }
    };
  }, [isAutoRollOn, endedChatModal]);

  useEffect(() => {
    let timer;
    const handleMouseMove = () => {
      setControlsVisible(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
      }, 3000);
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleUserMediaError = useCallback((error) => {
    console.error("Error accessing webcam:", error);
  }, []);

  useEffect(() => {
    const checkCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        stream.getTracks().forEach(track => track.stop());
        setWebcamLoading(false)
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          alert('Camera and microphone access both are required to start call');
        }
        else {
          alert('Camera or microphone access denied. Please allow access to start the call.');
        }
        setWebcamLoading(false)
      }
    };
    checkCameraAccess();
  }, []);

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = "https://app.asocial.chat/assets/loder-bg-CXJDv-hB.png"
    preloadImage.onload = () => {
      console.log("Image loaded successfully");
    };

    preloadImage.onerror = () => {
      console.log("Failed to load image");
    };
  }, []);

  return (
    <div>
      {
        <div className="video-start-main position-relative video-start-head">
          {(webcamLoading || (imagesLoading && (userDetail?.account_type != 0))) && (
            <div className="loader-style spinner-background-home">
              <Spinner />
            </div>
          )}
          {(isChatting && !isLoading) ? (
            <>
              {controlsVisible && <button
                type="button"
                onClick={handleNextClick}
                className="video-button next-call-position"
              >
                next{" "}
                <div className="arrow-btn-next">
                  <img src={IMAGES.ARROWPINK} className="arrow-animate"></img>
                </div>
              </button>}
              <div className="chat-window" onClick={handleSendButtonClick}>
                {controlsVisible && <div onClick={handleShowChatWindow} className="message-icon-links">
                  <img style={{ zIndex: "222" }} src={IMAGES.CHAT_ICON} alt="message-icon" className="video-call-icons" />
                </div>}
                {controlsVisible && <div style={{ cursor: "pointer" }} className="close-icon-links d-flex" onClick={() => setConfirmation(true)}><p className="text-white pt-2 mt-1 me-2">End Call</p>
                  <img style={{ zIndex: "222" }} src={IMAGES.TOGGLE_CLOSE} alt="message-icon" className="video-call-icons" />
                </div>}
                {(controlsVisible) && <div className="caution-icon-links d-flex" onClick={handleBlockUser}><p className="text-white pt-2 mt-1 me-2">Report</p>
                  <img style={{ zIndex: "222" }} src={IMAGES.CAUTION} alt="message-icon" className="video-call-icons" />
                </div>}
                {controlsVisible && <div onClick={handleMuteUnmute} className="mute-icon-links d-flex">{muteCall ? <p className="text-white pt-2 mt-1 me-2">Unmute</p> : <p className="text-white pt-2 mt-1 me-2" >Mute</p>}
                  <img style={{ zIndex: "222" }} src={muteCall ? IMAGES.UNMUTECALL : IMAGES.MUTECALL} alt="message-icon" className="video-call-icons" />
                </div>}
                {(controlsVisible && friendRequestAccountType && otherUser?.account_type !== 0 && (tryUserAccountType != null ? tryUserAccountType != 0 : true)) && (
                  <div onClick={handleFriendRequest} className="request-icon-links d-flex">
                    <p className="text-white pt-2 mt-1 me-2">
                      {friendRequest === null ? "Add Friend" : (friendRequest === 0 || friendRequest === 1) ? "Pending" : "Friend"}
                    </p>
                    <img
                      style={{ zIndex: "222" }}
                      src={
                        friendRequest === null ? IMAGES.ADD_FRIEND :
                          (friendRequest === 0 || friendRequest === 1) ? IMAGES.PENDING_REQUEST : IMAGES.APPROVED_REQUEST
                      }
                      alt="message-icon"
                      className="video-call-icons"
                    />
                  </div>
                )}
                <div className="">
                  <div className="video-stream ourvideo-call">
                    {myVideo && (
                      <VideoPlayer
                        id="myVideoElementId"
                        videoTrack={myVideo}
                        className="my-video"
                      />
                    )}
                  </div>
                  <div className="video-stream therevideo-call">
                    {themVideo && (
                      <VideoPlayer
                        id="themVideoElementId"
                        style={{ width: "100%", height: "100%" }}
                        videoTrack={themVideo}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="web-cam">
                {isLoading ?
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="loder-background">
                        <div className="position-relative">
                          <img src={IMAGES.LODER_LOGO} alt="logo-image" className="loder-logo" />
                          <Lottie animationData={loader_gif} loop={true} className="loder-svg" />
                          <img src={userDetail?.avatar ? IMAGEURL + userDetail.avatar : IMAGES.USER_ICON} alt="user-image" className={interest_matching == 1 ? "loder-user-icon" : "loder-user-img"}
                          />
                          <div className="text-center">
                            <p className="loder-user-text ps-5">{userDetail?.username}</p>
                          </div>
                          {/* {
                            interest_matching == 1 &&
                            <div className="text-center">
                              <p className="fw-600 text-white f18-size">Searching by interest...</p>
                            </div>
                          } */}
                          <div className="Connecting-status d-flex justify-content-start align-items-center pb-5">
                            <p className="connect-dots">Connecting</p>
                            <div className="spinner">
                              <div className="bounce1"></div>
                              <div className="bounce2"></div>
                              <div className="bounce3"></div>
                            </div>
                          </div>
                          <button type="button" onClick={() => handleLeaveCall()} className="cancel-btn-home">
                            Cancel
                          </button>
                        </div>
                        {/* {
                          interest_matching == 0 && */}

                        {/* } */}
                      </div>
                    </div>
                  </div>
                  :
                  <>

                    <Webcam
                      mirrored={true}
                      width={1580}
                      videoConstraints={videoConstraints}
                      onUserMediaError={handleUserMediaError}
                      onUserMedia={() => { setWebcamLoading(false) }}
                    />
                  </>
                }
              </div>
              {
                videoButtonOpen ? "" :
                  <>
                    {(!isLoading && webcamLoading == false) && <button type="button" onClick={handleStartCall} className="video-button video-btn-position">
                      <img src={IMAGES.STARTVIDEO}></img>Start video chat
                    </button>}
                  </>
              }

            </>
          )}
        </div>
      }
      {
        (isInterestModalOpen && interestArray?.length > 0) && <MatchedInterestModal setIsInterestModalOpen={setIsInterestModalOpen} interestArray={interestArray} otherUser={otherUser} check="call" />
      }
      {
        confrimation && <ConfirmModal setConfirmation={setConfirmation} handleLeaveCall={handleLeaveCall} setLeave={setLeave} check="randomCall" />
      }
    </div>
  );
};

export default VideoRoom;
